.flotmenuright {
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: block;
  height: 170px;
  width: auto;
  z-index: 99;
}
.flotmenuright a {
  width: 50px;
  height: 50px;
  line-height: 54px;
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  border-radius: 50%;
  display: block;
  color: #0066b3;
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
}
.flotmenuright a:hover {
  background: #0066b3;
  color: #fff;
}
.flotmenuright .call span {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: translateX(150px);
  display: none;
}
.flotmenuright .call {
  transition: all 0.3s ease-in-out;
}

.flotmenuright .call:hover span {
  opacity: 1;
  margin-left: 10px;
  transform: translateX(0px);
  display: block;
}
.flotmenuright .call:hover {
  width: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 30px;
  padding: 20px;
}
a:focus, a:hover {
  text-decoration: none;
}
@media (max-width: 575.98px) 
{
.flotmenuright {
  
display:none;
}
    
  
 }