
p, a, span, h1, h2, h3, h4, h5, h6, div ,li{
  font-family: 'Montserrat', sans-serif !important;
}


.colrbck{
  background-color: #F5F0EA;
}



.zoom {
  transition: transform 0.5s ease-in-out; 
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.09);
}


/* Facilities_Qualities */
.productionbx
{
      min-height: 260px;
      width: 100%;
      height: auto;
      overflow: hidden;
      background: #f9f9f9;
      box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 9%);
      padding: 25px;
      border-radius: 3px!important;
      margin-top: 15px;
      margin-bottom: 15px;
}
/* GlobalOperation.jsx */
.boxImgShadow{
  box-shadow: -1px -1px 11px rgb(0 0 0 / 27%);
}

/* Product ByTrade */

.productFilterContent{
    position: sticky;
    top: 120px;
}
.productFilterContent1{
  border: 1px solid #cccccc;
    border-radius: 3px;
    background: #f8f8f8;
    padding: 15px 20px;
    margin-top: 20px 0px 0px ;

}

.productFilterContent h3 {
  font-size: 24px;
  color: #565656;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}



@media (max-width: 575.98px) 
{
    
  .Responsive_Margin_Screen{
        margin: 30px;
  }
 }
@media (max-width: 767.98px)
{ 
  .Responsive_Margin_Screen{
    margin: 30px;
  }
 }

@media (max-width: 991.98px) { 

  .Responsive_Margin_Screen{
    margin: 30px;
  }
 }


 /* Contact Us Page */
 .serviceBox1{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
 }
.service-content1{
  background: #f3f0f0;
  border-bottom: 3px solid #0066b2 !important;
  border-radius: 3px 0 0 3px;
  padding: 18px 8px;
  width: 73% !important;
  margin: 10px 0;
  text-align: center !important;
  font-weight: 500 !important;
}
.service-icon1{
  background: #0066b2;
  border-bottom: 3px solid #0066b2;
  color: #ffffff;
  font-size: 25px;
  padding: 35px 0 0;
  text-align: center;
  width: 80px;
}
.service-content1 h3 {
  color: hsl(0, 0%, 7%);
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
}
.mappadns{
  padding-left: 15px!important;
  padding-right: 15px!important;
  }
.form-div {
    padding: 60px 30px 2px 30px;
    background: transparent;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(0 0 0 / 36%);
}


.socialmediapostlink:hover{
  text-decoration-color: #0066b2;
}
.socialmediapostlink{
 text-decoration: underline;
 text-decoration-color: transparent;
 transition: 1s ease-in-out;
 cursor: pointer;
}


/* Login Modal Css */
.cardlogmodel {
  border: none !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #0066b3 !important;
  border: none !important;
}
.btr {
  color: #000000 !important;
}


.btl {
  color: #000000 !important;
}

.nav-pills .nav-link {
  border-radius: 0 !important;

}

.nav-pills {
  border: none !important;
  display: table !important;
  width: 100%
}

.modal-header {
  justify-content: center !important;
}


.nav-item {
  border: none !important;

  display: table-cell;
}

.logmod::placeholder{
  font-size: 14px;
}

.logform input {
  margin-bottom: 12px;
  border-radius: 3px
}

.logform input:focus {
  box-shadow: none
}

.logform button {
  margin-top: 20px;
}

.iconhover:hover{
  color:red;
  cursor: pointer;
}

/* Facilities */
.allborder {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 5px;
  border: 1px #eee solid;
  margin-top: 5px;
}


/* Nav Drop Down Link Active Color OverWrite */
.dropdown-item.active  {
  color: #337ab7 !important; 
  background-color: transparent !important;
}






.headerBtn-red:hover {
  background-color: #565656;
  color: #fff;
  padding: 5px;

  padding-left: 24px;
  padding-right: 24px;
}
.headerBtn-red {
  margin-top: 10px;
  border-radius: 20px;
  background-color: #0066b3;
  border: 1px solid transparent;
  color: #fff;
  min-width: 90px;
  padding: 5px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}


.text-error{
  margin-top: 0px;
  padding: 0px;
  height: 10px;
  position: absolute;
  top: 65px;
  font-size: 11px;
  color: red;
  text-transform: capitalize;
}



.style_custom{
  -webkit-box-shadow: 2px 0px 12px -8px rgba(0,0,0,0.68);
  -moz-box-shadow: 2px 0px 12px -8px rgba(0,0,0,0.68);
  box-shadow: 2px 0px 12px -8px rgba(0,0,0,0.68)
}



.input-login-modal {
  width: 100%;
  height: 42px;
  overflow: hidden;
  float: left;
  border: 1px lightgray solid;
  border-radius: 2px !important;
  padding-left: 12px;
  margin-bottom: 20px;
  color: #a9a9a9;
}
.label-name-login {
  color: #444;
  font-weight: 400;
  font-size: 15px;
}


.Select-menu-outer {
  position: relative;
  }

  .dropdown_custom_toogle::after {
    color: #fff !important;
   
}



.chatbutton{
  background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
.mf-content {
  padding: 12px 15px 13px;
  border-radius: 2px;
  display: inline-block;
  max-width: 80%
}
.mf-date {
  display: block;
  color: #B3B3B3;
  margin-top: 7px;
}
.mf-date>i {
  font-size: 14px;
  line-height: 100%;
  position: relative;
  top: 1px;
}
.incoming_msg_img {
  display: inline-block;
  width: 8%;
}
img {max-width: 100%; }
		.inbox_msg {
      
		  border: 1px solid #c4c4c4;
		  clear: both;
		  overflow: hidden;
		}
  

.browsebutton{
  background: #3297fa;
    border-radius: 0;
    color: #fff;
    font-size: 12px;
    padding: 5px 12px;
    position: absolute;
    right: 6px;
    text-align: center;
    top: 6px;
}
.file input[type="file"] {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 100;
}
.form-control[type=file] {
  overflow: hidden;
}


.page-404-default {
  padding: 80px 0 160px;
}
.page-404 {
  text-align: center;
}


.paginationcustom {
  margin: 20px auto !important;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center !important;
}
.paginationcustom > .active > a{
  background-color: #0066b3 ;
  border-color: #0066b3 ;
  color: #fff !important;
  font-weight: 500 !important;
}
.paginationcustom > li > a{
  border: 1px solid #0066b3 ;
  padding: 15px 20px ;
  font-size:20px !important;
  outline: none;
  font-weight: 500 !important;
  
  cursor: pointer;
}
.paginationcustom > .active > a, .paginationcustom > .active > span, .paginationcustom > .active > a:hover, .paginationcustom > .active > span:hover, .paginationcustom > .active > a:focus, .paginationcustom > .active > span:focus{
  background-color: #0066b3 ;
  border-color: #0066b3;
  
  outline: none ;
}
.paginationcustom > li > a, .paginationcustom > li > span{
  color: #0066b3;
}
.paginationcustom > li:first-child > a, .paginationcustom > li:first-child > span, .paginationcustom > li:last-child > a, .paginationcustom > li:last-child > span{
  border-radius: unset
}
@media (max-width: 767.98px)
{ 
  .paginationcustom > li > a{
    padding: 5px 6px ;
  }
  
 
}
.center-childrens {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

