/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}


.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.sidecontainer {
  display: grid;
  height: 100vh;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 0.1fr 3fr;
  grid-template-areas:
    "sidebar nav "
    "sidebar main"
    ;
}

@media only screen and (max-width: 978px) {
  .sidecontainer {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
}


.colrcardblue{
  background-color:#459ED8 ;
}
.colrcardseagreen{
  background-color:#22A6AC ;
  
}
.colrcardred{
  background-color:#F05179 ;
  
}
.colrcardorange{
  background-color:#F89747 ;
  
}
.react-bootstrap-table table {
  table-layout: auto !important;
}

#filtersubmit {
  position: absolute;
  width: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  color: #7B7B7B;
}
/* .form-control {
  padding: 0.375rem 2.5rem !important;
  border-radius: 10px !important;
} */

.table-sm>:not(caption)>*>* {
  padding: 1rem .25rem !important;
  border-bottom-width: 0px;
}
.table {
  color: #565656 !important;
}

.btn-secondary {
  color: #fff;
  background-color: #0066b3 !important;
  border-color: #0066b3 !important;
}

.page-link {
  color: #0066b3 !important;
}
.page-item.active .page-link {
  background-color: #0066b3 !important;
  color: #fff !important;

}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: #565656 !important;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6 !important;
}

