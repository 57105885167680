@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
.caption {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    color: white;
    animation-duration: 1s;
    animation-delay: 2s;
  }
  
  .caption h2 {
    animation-duration: 1s;
    animation-delay: 2s;
  }
  
  .caption p {
    animation-duration: 1s;
    animation-delay: 2.2s;
  }
  
  .caption a {
    animation-duration: 1s;
    animation-delay: 2.4s;
  }
  
  .cen{
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
  
  .slider-wrapper {
    position: relative;
  }
  .slider-wrapper .slider-overlay {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 80%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction:inherit;
    justify-content: center;
    /* background: rgba(134, 134, 134, 0.2); */
  
  }

  .zoom-in-zoom-out {
    animation: zoom-in-zoom-out 25s ease-out infinite;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }


  .wrap {
    height: 200px;
    width: 300px;
    overflow-y: hidden;
    position: relative;
}


.wrap > div.block_1 {
    animation-name: block1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.wrap > div.block_2 {
    animation-name: block2;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 3s;
}

.wrap > div.block_3 {
    animation-name: block3;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 5s;
}

@keyframes block1 {
    0% {
        top: -100px;
    }
    100% {
        top: 0;
    }
}

@keyframes block2 {
    0% {
        top: -100px;
    }
    100% {
        top: 40px;
    }
}

@keyframes block3 {
    0% {
        top: -100px;
    }
    100% {
        top: 80px;
    }
}

/* Jumping Animation Bottom To Top */
.ball {
  animation: opacity 2s, bounce 1.5s infinite alternate;
  -webkit-animation: opacity 2s, bounce 1.6s infinite alternate;
  animation-delay: 1s;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(35px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(35px);
  }
}
.typography_Heading{ 
  
  font-family: 'Montserrat', sans-serif;
  font-size: 3vw !important;
  font-weight: 600 !important;
    text-transform: uppercase;
}
.img-inline-res{
  width: calc(100% - 70%);
  margin: 0px 15px 0px 0px !important;
}

.btn-custom-animation:hover{
    border: 1px solid #0066b3 !important;
    color: #000 !important;
    background-color:#fff !important;
}

.typograpy_title{
  font-size: 2vw;
  color:#fff;
  font-weight: 400 !important;
}
.typograpy_title0{
  font-size: 2vw!important;
  color:#fff;
  font-family: 'Dancing Script', cursive !important;
}

.btn_text_typography{
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 12px !important;
  padding: 12px 30px !important;
}


/* Slick Dots Ka Color Ki Css */
.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 10px;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  display: block;
  height: 20px;
  width: 20px;
  outline: blue!important;
  line-height: 0;
  font-size: 0;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  transform: scale(1);
}
.slick-dots li button:hover:before{
  transform: scale(1.5);
  color: red !important;
  outline: red!important;
}
.slick-dots li button:focus:before {
  transform: scale(1);
  color: red !important;
  outline: red!important;
}

.slick-dots li button:before {
  position: absolute;
  content: "•";
  font-family: "slick";
  font-size: 8px;
  padding-top: 9px;
  line-height: 2px;
  text-align: center;
  color: red !important;
  overflow:hidden !important;
  opacity: unset;
}

.slick-dots li.slick-active button:before {
      border: 1px solid  blue !important;
      border-radius: 50% !important;
      color: blue !important;
      cursor:unset;
}

/* Bottom To Top Hover Ki Button Animation */


/* .btn-outline-primary-carousel{
  background-color:blue !important;
  background-position: 50% 100% !important; 
  background-repeat: no-repeat !important;
  transition: background-size .5s, color .5s !important;
} */
.slick-initialized .slick-slide.slick-active {
  z-index: 1 !important;
}


@media (max-width: 480px) 
{

  .btn_text_typography{
  font-size: 5px !important;
  padding: 0 !important;
  }
}
